<template>
  <div>
    <nav class="navbar navbar-custom">
      <div class="container-fluid flex justify-content-between">
        <a class="navbar-brand d-inline-block">
          <img
            class="logo-img"
            src="../assets/logo_letmein.png"
            alt="Letmein LOGO"
          >
        </a>
        <select
          id="langSelected"
          v-model="locale"
          class="form-select d-inline-block"
          aria-label="Default select example"
          @change="reload()"
        >
          <option
            v-for="(msg, i) in language"
            :key="`locale${i}`"
            :value="i"
          > 
            {{ msg }}
          </option>
        </select>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'NavbarComponents',
  data() {
    return {
      language: {
        en: "EN",
        th: "TH"
      }
    }
  },
  computed: {
    locale: {
      get: function() {
        // if (this.$route.name != "calparking" && this.$route.name != "parkingcondition") {
        //   this.$i18n.locale = this.$store.state.locale
        // }
        console.log("get: ", this.$store.state.locale);
        return this.$store.state.locale
      },
      set: function(newVal) {
        console.log("set: ", newVal);
        this.$store.dispatch('changeLocale', newVal)
      }
    }
  },
  methods: {
    reload() {
      if (this.$route.name != "paymentparking") {
        this.$router.go()
      }
    }
  },
}
</script>

<style>
.navbar-custom {
  background-color: #3f4855;
  padding: 1rem 0;
  height: 100px
}

.logo-img {
  max-width: 200px;
  width: 100%;
  height: auto;
}

select#langSelected {
  width: 70px;
  background-color: transparent !important;
  border: none !important;
  color: white !important;
  border-radius: 0 !important;
  border-bottom: 1px solid !important;
  outline: none !important;
  background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27><path fill=%27none%27 stroke=%27white%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/></svg>") !important;
  background-repeat: no-repeat !important;
  background-position-x: 90% !important;
  background-position-y: 50% !important;
}

</style>
