<template>
  <Navbar v-if="$route.name != 'qrscanner'" />
  <div class="container-custom">
    <router-view :key="$route.name" />
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";

export default {
  name: "App",
  components: {
    Navbar,
  },
  mounted() {
    document.title = "LETMEIN: Smart Living and Workplace Platform";
    const link = document.querySelector("[rel='icon']");
    link.setAttribute("href", "icon.png");
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

body {
  margin: 0;
  height: 100%;
  /* overflow: hidden; */
  position: relative;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.payment-header {
  width: 100%;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.05;
  letter-spacing: normal;
  text-align: left;
  color: #2dc86e;
  display: inline;
  margin-right: 2%;
}

.payment-success {
  display: inline;
  width: 50px;
  height: 45px;
}

.payment-text {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  line-height: 2;
}

.label-btn {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 15px;
  margin: 0px;
}

.btn-payment {
  background-color: #3f4955;
  color: white;
  padding: 8px;
  margin-top: 40px;
  width: 100%;
  border-radius: 5px;
  border: none;
}

.btn-payment-disable {
  background-color: grey;
  color: white;
  padding: 8px;
  margin-top: 40px;
  width: 100%;
  border-radius: 5px;
  border: none;
}

.container-custom {
  max-width: 768px;
  width: 100%;
  margin: auto;
}
</style>
