export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking Fee Calculator"])},
  "textCalParking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter 4 digit pin below QR Code or Scan QR Code"])},
  "placeholderCalParking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter 4 digit pin code"])},
  "btnScanQR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan QR Code"])},
  "btnCalculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculate"])},
  "calLabel1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 digit QR Code pin"])},
  "calLabel2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name"])},
  "calLabel3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity ID/Passport No."])},
  "calLabel4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room No"])},
  "calLabel5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle"])},
  "calLabel6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License plate"])},
  "calLabel7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stamp"])},
  "calLabel8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry Time"])},
  "calLabel9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit Time"])},
  "calLabel10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking duration"])},
  "calLabel11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking Fee"])},
  "calLabel12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
  "calLabel13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total parking fee"])},
  "btnPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
  "parkingCondition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking Terms and Conditions"])},
  "paymentSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment success"])},
  "paymentDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please bring out a car from a parking spot and exit the building within 30 minutes, after the payment."])},
  "sessionExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session expired"])},
  "sessionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This QR Code has been expired."])},
  "paymentAgain1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please press the "])},
  "paymentAgain2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["button and try again."])},
  "conditionLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking Conditions"])},
  "btnBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "paymentTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This QR Code will expire in"])},
  "timeUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutes"])},
  "btnSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save QR Code"])},
  "descriptionPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan this QR Code for payment"])},
  "warningPayment1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please bring out a car from a parking spot and scan"])},
  "warningPayment2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR on receipt at security gate, after the payment."])},
  "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "btnCheckStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Payment"])},
  "btnOk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "errorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your payment is time out. Please try again."])},
  "btnSaveInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Invoice"])},
  "btnbacktoHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Homepage"])},
  "paymentMethodLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Method"])},
  "penaltyLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penalty"])},
  "stampTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking Stamp"])},
  "stampMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking E-Stamp is available at commercial shops or you can proceed with the payment"])},
  "checkoutTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Checkout"])},
  "checkoutMessage1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have"])},
  "checkoutMessage2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutes to exit the building after check-out."])},
  "confirmTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed with the payment ?"])},
  "confirmTitleWithStamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Payment"])},
  "confirmTitleOverdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdue Parking"])},
  "confirmMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can get your ticket stamped at the stores or designated parking ticket stamp desks."])},
  "confirmMessageWithStamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed with the payment ?"])},
  "confirmMessageOverdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please proceed with the payment of an overdue parking fee before exit the building."])},
  "btnPayNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Now"])},
  "btnCheckOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkout"])},
  "btnConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "btnPayLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Later"])},
  "scanMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place QR code within provided area."])}
}